import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { isVerificationOpenAtom, tryForFreeEmailAtom } from 'store';
import { useAuth } from 'util/auth';

const AuthForm = (props) => {
  const [, setIsVerificationOpen] = useAtom(isVerificationOpenAtom);
  const [tryForFreeEmail, setTryForFreeEmail] = useAtom(tryForFreeEmailAtom);

  const auth = useAuth();

  const [pending, setPending] = useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        setPending(false);
        props.handleClose && props.handleClose();
        props.onAuth && props.onAuth(user);
      });
    },
    signup: ({ email, pass }) => {
      return auth.signup(email, pass).then((user) => {
        // Call auth complete handler
        setIsVerificationOpen(true);
        if (props.handleClose) {
          props.handleClose();
        }
      });
    },
    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Password reset email sent',
        });
      });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Your password has been changed',
        });
      });
    },
    enterprise: ({ email, pass, licenseKey }) => {
      return auth.enterprise(email, pass, licenseKey).then((user) => {
        setPending(false);
        if (props.handleClose) {
          props.handleClose();
        }
        props.onAuth && props.onAuth(user);
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass, licenseKey }) => {
    // Show pending indicator
    setPending(true);
    // Call submit handler for auth type

    submitHandlersByType[props.type]({
      email,
      pass,
      licenseKey,
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      props.onFormAlert({
        type: 'error',
        message: error.message,
        code: error.code,
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2} justifyContent="center">
        {['signup', 'signin', 'forgotpass', 'enterprise'].includes(
          props.type
        ) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="email"
              label="Email"
              autoComplete="email"
              placeholder="user@example.com"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              defaultValue={tryForFreeEmail}
              fullWidth={true}
              {...register('email', {
                required: 'Please enter your email',
                onChange: (e) => {
                  setTryForFreeEmail(e.target.value);
                },
              })}
            />
          </Grid>
        )}

        {['signup', 'signin', 'changepass', 'enterprise'].includes(
          props.type
        ) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="password"
              autoComplete="current-password"
              label="Password"
              error={!!errors.pass}
              helperText={errors.pass && errors.pass.message}
              fullWidth={true}
              {...register('pass', {
                required: 'Please enter a password',
              })}
            />
          </Grid>
        )}

        {['signup', 'changepass', 'enterprise'].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="password"
              autoComplete="current-password"
              label="Confirm Password"
              error={!!errors.confirmPass}
              helperText={errors.confirmPass && errors.confirmPass.message}
              fullWidth={true}
              {...register('confirmPass', {
                required: 'Please enter your password again',
                validate: (value) =>
                  value === getValues('pass') ||
                  "This doesn't match your password",
              })}
            />
          </Grid>
        )}

        {['enterprise'].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="licenseKey"
              autoComplete="licenseKey"
              label="License Key"
              error={!!errors.licenseKey}
              helperText={errors.licenseKey && errors.licenseKey.message}
              fullWidth={true}
              {...register('licenseKey', {
                required: 'Please enter your license',
                validate: (value) =>
                  value === getValues('licenseKey') ||
                  'Please enter your license',
              })}
            />
          </Grid>
        )}

        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {pending ? (
              <CircularProgress size={28} />
            ) : (
              <span>{props.buttonAction}</span>
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AuthForm;
