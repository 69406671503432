import { Box, Button } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

const dotFlashing = keyframes`
  0% {
    background-color: #ff006a;
  }
  50%, 100% {
    background-color: rgba(255, 0, 106, 0.3);
  }
`;

export const StyledChatBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundImage:
    'linear-gradient(to bottom, #6598cb, #7eaad7, #96bce3, #aecef0, #c6e1fc)',
  textAlign: 'center',
  borderRadius: '10px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: 6,
}));

export const StyledIcon = styled('img')({
  width: '25px',
  height: '25px',
});

export const StyledButton = styled(Button)({
  minWidth: '0',
  display: 'block',
  height: '20px',
  marginLeft: 'auto',
  padding: 0,
  cursor: 'pointer',
  '& .MuiButton-startIcon': {
    margin: 0,
    color: 'white',
  },
});

export const DividedLine = styled('div')({
  width: 'calc(100% + 12px)',
  borderBottom: '1px solid white',
  marginLeft: '-6px',
});

export const LoadingProgress = styled('div')({
  zIndex: '9999',
  position: 'absolute',
  right: '50%',
  bottom: '70px',
  transform: 'translate(-50%, -50%)',
  width: '10px',
  height: '10px',
  borderRadius: '5px',
  backgroundColor: '#ff006a',
  color: '#ff006a',
  animation: `${dotFlashing} 1s infinite linear alternate`,
  animationDelay: '0.5s',
  '&::before, &::after': {
    content: "''",
    display: 'inline-block',
    position: 'absolute',
    top: '0',
  },
  '&::before': {
    left: '-15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#ff006a',
    color: '#ff006a',
    animation: `${dotFlashing} 1s infinite alternate`,
    animationDelay: '0s',
  },
  '&::after': {
    left: '15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#ff006a',
    color: '#ff006a',
    animation: `${dotFlashing} 1s infinite alternate`,
    animationDelay: '1s',
  },
});

export const StyledActionBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ isFullScreen }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: isFullScreen ? 'row-reverse' : 'column',
  maxWidth: isFullScreen ? '600px' : '100%',
  minWidth: isFullScreen ? '300px' : 'unset',
  alignSelf: 'center',
  width: '100%',
}));

export const StyledResetButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ isFullScreen }) => ({
  borderRadius: '10px',
  maxWidth: '180px',
  margin: isFullScreen ? '' : '0 auto',
  marginBottom: isFullScreen ? '0' : '15px',
  borderColor: '#6598cb',
  color: '#6598cb',
}));

export const ChatForm = styled('form')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const StyledModeButton = styled(StyledButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  border: isSelected && '1px solid #ffffff',
  borderBottomStyle: 'none',
  borderRadius: '7px',
  minWidth: '70px',
  minHeight: '35px',
  padding: '0.5em 0.5em 0.5em',
  color: isSelected ? '#ffffff' : '#000000',
  backgroundColor: isSelected ? 'transparent' : '#165d9c7a',
  '&:hover': {
    backgroundColor: isSelected ? 'transparent' : '#124a7a7a',
  },
  borderBottomRightRadius: '0px',
  borderBottomLeftRadius: '0px',
}));
