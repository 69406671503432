import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Box } from '@mui/material';

const selectStyles = {
  container: (base) => ({
    ...base,
    '& div': {
      overflowX: 'clip',
      textOverflow: 'ellipsis',
      borderRadius: '10px',
    },
  }),
};

const CollectionSelector = ({
  selectedMode,
  isCollectionsLoading,
  options,
  placeholder,
  selectCollection,
  handleChange,
  handleCreate,
  isFullScreen,
  isExtended,
  isBrainCollectionsLoading,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: isFullScreen ? '60%' : '100%',
      }}
    >
      <Box
        onDoubleClick={(e) => e.stopPropagation()}
        width={isFullScreen ? '180px' : '100%'}
        maxWidth="230px"
        color="black"
        textOverflow="ellipsis"
        sx={{ textWrap: 'nowrap' }}
      >
        {selectedMode === 'Private AI' ? (
          <Select
            styles={selectStyles}
            isDisabled={isCollectionsLoading}
            isLoading={isCollectionsLoading || isBrainCollectionsLoading}
            onChange={handleChange}
            options={options}
            value={selectCollection}
            placeholder={placeholder}
            menuPlacement={isExtended ? 'top' : 'bottom'}
          />
        ) : (
          <CreatableSelect
            styles={selectStyles}
            isDisabled={isCollectionsLoading}
            isLoading={isCollectionsLoading || isBrainCollectionsLoading}
            onChange={handleChange}
            onCreateOption={handleCreate}
            options={options}
            value={selectCollection}
            placeholder={placeholder}
            menuPlacement={isExtended ? 'top' : 'bottom'}
          />
        )}
      </Box>
    </Box>
  );
};

export default CollectionSelector;
