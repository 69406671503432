export const PATHS = Object.freeze({
  _COMPANY: 'company',
  _FEATURES: 'features',
  _PRICING: 'pricing',
  _PAYWALL: 'paywall',
  _CHECKOUT_SUCCESS: 'checkout-success',
  _CHECKOUT_CANCEL: 'checkout-cancel',
  _BOOKMARKS: 'bookmarks',
  _COLLECTIONS: 'collections',
  _COLLECTION: 'collection',
  _GET_YOUR_SURFER_BUDDY: 'get-your-surfer-buddy',
  _GET_MOBILE_APP: 'get-the-mobile-app',
  _DOWNLOAD: 'download',
  _PERSONAL_WORKSPACE: 'personal-workspace',
  _RECIPES: 'recipes',
  _REFERRAL: 'referral',
  _SHARED: 'shared',
  _TAGS: 'tags',
  _TRYFORFREE: 'tryforfree',
  _ORGANIZE: 'organize',
  _CREDITS: 'credits',
  _AUTH: 'auth',
  _SETTINGS: 'settings',
  _SUPPORT: 'support',
  _TERMS_AND_PRIVACY: 'terms-and-privacy',
  _COOKIE_POLICY: 'cookie-policy',
  _CONTACTS: 'contacts',
  _FIREBASE_ACTION: 'firebase-action',
  _DOCUMENTS: 'documents',
  _PRODUCTS_PAGE: 'products-page',
  _ENTERPRISE_SIGNUP: 'enterprise-signup',
  _APPLY_PAGE: 'apply-page',

  get COMPANY() {
    return `/${this._COMPANY}`;
  },
  get CONTACTS() {
    return `/${this._CONTACTS}`;
  },
  get FEATURES() {
    return `/${this._FEATURES}`;
  },
  get SUPPORT() {
    return `/${this._SUPPORT}`;
  },
  get PRICING() {
    return `/${this._PRICING}`;
  },
  get PAYWALL() {
    return `/${this._PAYWALL}`;
  },
  get CHECKOUT_SUCCESS() {
    return `/${this._CHECKOUT_SUCCESS}`;
  },
  get CHECKOUT_CANCEL() {
    return `/${this._CHECKOUT_CANCEL}`;
  },
  get BOOKMARKS() {
    return `/${this._BOOKMARKS}`;
  },
  get COLLECTIONS() {
    return `/${this._COLLECTIONS}`;
  },
  get COLLECTION_TEMPLATE() {
    return `/${this._COLLECTION}/:collectionId`;
  },
  get GET_YOUR_SURFER_BUDDY() {
    return `/${this._GET_YOUR_SURFER_BUDDY}`;
  },
  get GET_MOBILE_APP() {
    return `/${this._GET_MOBILE_APP}`;
  },
  get DOWNLOAD() {
    return `/${this._DOWNLOAD}`;
  },
  get PERSONAL_WORKSPACE() {
    return `/${this._PERSONAL_WORKSPACE}`;
  },
  get RECIPES() {
    return `/${this._RECIPES}`;
  },
  get REFERRAL() {
    return `/${this._REFERRAL}`;
  },
  COLLECTION(collectionId) {
    return `/${this._COLLECTION}/${collectionId}`;
  },
  get COLLECTION_PAGE_TEMPLATE() {
    return `/${this._COLLECTION}/:collectionId/:pageId`;
  },
  COLLECTION_PAGE(collectionId, pageId) {
    return `/${this._COLLECTION}/${collectionId}/${pageId}`;
  },
  get SHARED_TEMPLATE() {
    return `/${this._SHARED}/:sharingUid/:collectionId`;
  },
  SHARED(sharingUid, collectionId) {
    return `/${this._SHARED}/${sharingUid}/${collectionId}`;
  },
  get TAGS() {
    return `/${this._TAGS}`;
  },
  get TRYFORFREE() {
    return `/${this._TRYFORFREE}`;
  },
  get ORGANIZE() {
    return `/${this._ORGANIZE}`;
  },
  get CREDITS() {
    return `/${this._CREDITS}`;
  },
  get AUTH_TEMPLATE() {
    return `/${this._AUTH}/:type`;
  },
  AUTH(type) {
    return `/${this._AUTH}/${type}`;
  },
  get SETTINGS_TEMPLATE() {
    return `/${this._SETTINGS}/:section`;
  },
  SETTINGS(section) {
    return `/${this._SETTINGS}/${section}`;
  },
  get TERMS_AND_PRIVACY() {
    return `/${this._TERMS_AND_PRIVACY}`;
  },
  get COOKIE_POLICY() {
    return `/${this._COOKIE_POLICY}`;
  },
  get FIREBASE_ACTION() {
    return `/${this._FIREBASE_ACTION}`;
  },
  get DOCUMENTS_TEMPLATE() {
    return `/${this._DOCUMENTS}/:collectionId`;
  },
  DOCUMENTS(collectionId) {
    return `/${this._DOCUMENTS}/${collectionId}`;
  },
  get PRODUCTS_PAGE() {
    return `/${this._PRODUCTS_PAGE}`;
  },
  get ENTERPRISE_SIGNUP() {
    return `/${this._ENTERPRISE_SIGNUP}`;
  },
  get APPLY_PAGE() {
    return `/${this._APPLY_PAGE}`;
  },
});
