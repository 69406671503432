import {
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
  useQuery,
} from 'react-query';
import * as fflate from 'fflate';
import { encode } from 'firebase-key';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';

import {
  deleteFilesFromFolder,
  deleteScreenshotImg,
  markdownToHtml,
  uploadDataUrlFile,
} from './files';
import { firebaseApp } from './firebase';
import { createCollectionUrl, emailSender } from './firebasefunctions';
// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ['user', { uid }],
    // Firestore query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, 'users', uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, 'users', uid)).then(format);
}

export function getReferralCode(referralCodeId) {
  return getDoc(doc(db, 'referralCodes', referralCodeId)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, 'users', uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, 'users', uid), {
    ...data,
    updatedAt: serverTimestamp(),
  });
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ['item', { id }],
    createQuery(() => doc(db, 'items', id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ['item', { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, 'items', id)).then(format),
    { enabled: !!id }
  );
}

// Fetch item data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getItem(id) {
  return getDoc(doc(db, 'items', id)).then(format);
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ['items', { owner }],
    createQuery(() =>
      query(
        collection(db, 'items'),
        where('owner', '==', owner),
        orderBy('createdAt', 'desc')
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, 'items'), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, 'items', id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, 'items', id));
}

/****NO IMAGES****/
export async function getNoImg() {
  return await getDoc(doc(db, 'images', 'noPicOffCenter')).then(format);
}

/****COLLECTIONS***/
export function useCollectionsByOwner(owner) {
  return useQuery(
    ['collections', { owner }],
    createQuery(() => query(collection(db, 'users', owner, 'collections'))),
    { enabled: !!owner }
  );
}

//Get a list
export function getListByOwner(owner, listname) {
  return getDoc(doc(db, 'users', owner, 'collections', listname));
}

export const getFaq = async () => {
  const docSnap = await getDoc(doc(db, 'faq', 'content'));

  if (docSnap.exists()) {
    return docSnap.data().items;
  } else {
    return [];
  }
};

export const getTermsAndPrivacy = async () => {
  const docSnap = await getDoc(doc(db, 'termsAndPrivacy', 'content'));

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return '';
  }
};

export const getPrivacyPolicy = async () => {
  const docSnap = await getDoc(doc(db, 'privacyPolicy', 'content'));

  if (docSnap.exists()) {
    return docSnap.data().html;
  } else {
    return '';
  }
};

export const getTermsOfService = async () => {
  const docSnap = await getDoc(doc(db, 'termsOfService', 'content'));

  if (docSnap.exists()) {
    return docSnap.data().html;
  } else {
    return '';
  }
};

// Subscribe to a list
export function useListByOwner(owner, listname) {
  return useQuery(
    [listname, { owner }],
    createQuery(() => query(doc(db, 'users', owner, 'collections', listname))),
    { enabled: !!owner }
  );
}

export async function addCollectionToUrlDoc(data) {
  //Läs in url document
  const urlRef = doc(db, 'users', data.owner, 'urls', data.urlId);
  // Atomically add a collection from the "collections" array field.
  await updateDoc(urlRef, {
    collections: arrayUnion(data.listId),
  });
}

export async function addOrganizeTJ(data) {
  const urlRef = doc(db, 'users', data.owner, 'urls', data.urlId);
  //Check if collectionId is not null to prevent crash if card is dropped on
  //a collectionAlias without cards
  if (data.collectionId) {
    const collectionRef = doc(
      db,
      'users',
      data.owner,
      'collections',
      data.collectionId
    );

    /*     console.log('data.sharedUrl i addOrganizeTJ', data.sharedUrl);
    console.log('data.sharedCollection i addOrganizeTJ', data.sharedCollection); */

    let shared = false;
    //data.sharedUrl ? (shared = data.sharedUrl) : (shared = false);

    //The url is added to a shared collection, shared must be set to true
    if (data.sharedCollection === true) {
      shared = true;
    } else {
      //Hämta från db ELLER global variabel/local db ELLER atom?
      /* const filtered = data.collections.filter(
        (value) => value !== data.collectionId
      );
      console.log('I else filtered', filtered);
      console.log('INNAN KÖRNING');
      filtered.length > 0 &&
        checkCollectionsSharedStatus({
          collectionArray: filtered,
          documentRef: urlRef,
          owner: data.owner,
        }); */
    }
    // Get a new write batch
    const batch = writeBatch(db);

    batch.set(
      collectionRef,
      {
        owner: data.owner,
        timestamp: serverTimestamp(),
        urls: arrayUnion(data.urlId),
      },
      { merge: true }
    );
    if (shared === true) {
      batch.set(
        urlRef,
        {
          timestamp: serverTimestamp(),
          owner: data.owner,
          shared: shared,
          collections: arrayUnion(data.collectionId),
        },
        { merge: true }
      );
      await batch.commit();
    } else {
      batch.set(
        urlRef,
        {
          timestamp: serverTimestamp(),
          owner: data.owner,
          collections: arrayUnion(data.collectionId),
        },
        { merge: true }
      );
      await batch.commit();

      //väntar vi på batche???
      const docSnap = await getDoc(urlRef);

      if (docSnap.exists()) {
        checkCollectionsSharedStatus({
          collectionArray: docSnap.data().collections,
          documentRef: urlRef,
          owner: data.owner,
        });
      } else {
        // doc.data() will be undefined in this case
      }

      //fråga
    }

    // Commit the batch
    // await batch.commit();

    //väntar vi på batche???
    //fråga
  }
}

export async function removeOrganizeTJ(data) {
  const urlRef = doc(db, 'users', data.owner, 'urls', data.urlId);
  const collectionRef = doc(
    db,
    'users',
    data.owner,
    'collections',
    data.collectionId
  );
  let shared = false;
  data.sharedUrl ? (shared = data.sharedUrl) : (shared = false);

  if (data.sharedCollection === true && data.collections.length === 1) {
    shared = false;
  } else {
  }

  //The url is added to a shared collection, shared must be set to true

  //Enda där jag behöver ev. ändra är om data.sharedUrl===true OCH FRÅN Kollektionen också är shared

  // Get a new write batch
  const batch = writeBatch(db);

  batch.set(
    collectionRef,
    {
      owner: data.owner,
      timestamp: serverTimestamp(),
      urls: arrayRemove(data.urlId),
    },
    { merge: true }
  );
  batch.set(
    urlRef,
    {
      timestamp: serverTimestamp(),
      owner: data.owner,
      shared: shared,
      collections: arrayRemove(data.collectionId),
    },
    { merge: true }
  );
  // Commit the batch
  await batch.commit();
}

export async function moveToTopOrganizeTJ(data) {
  //Update timestamp -> moves card to top in sort order in collections
  const urlRef = doc(db, 'users', data.owner, 'urls', data.urlId);
  setDoc(urlRef, { timestamp: serverTimestamp() }, { merge: true });
}

export async function deletePageandRemovefromCollectionsTJ(data) {
  //TJ VAD GÖR VI MED TAGS?? FIXAS I FB FUNCTIONS!!
  const batch = writeBatch(db);

  const collections = data.collections;

  for (let el of collections) {
    batch.set(
      doc(db, 'users', data.owner, 'collections', el),
      {
        owner: data.owner,
        timestamp: serverTimestamp(),
        urls: arrayRemove(data.urlId),
      },
      { merge: true }
    );
    const isStoreCover = await checkIsCollectionCoverInStore(data.owner, el);
    if (!isStoreCover && data.screenshotName) {
      await deleteScreenshotImg(data.owner, el, data.screenshotName);
    }
  }

  /* data.tags.forEach((el) => {
    batch.set(
      doc(db, 'users', data.owner, 'tags', 'tags'),
      {
        owner: data.owner,
        timestamp: serverTimestamp(),
        tags: arrayRemove({ urlId: data.urlId, label: el }),
      },
      { merge: true }
    );
  }); */

  //Delete page
  batch.delete(doc(db, 'users', data.owner, 'urls', data.urlId));

  if (data.aiResponse) {
    batch.delete(doc(db, 'users', data.owner, 'aiResponses', data.aiResponse));
  }

  await deleteFilesFromFolder(`offlinePages/${data.owner}/${data.urlId}`);

  await batch.commit();
}

export async function removeCollectionFromUrlDoc(data) {
  const urlRef = doc(db, 'users', data.owner, 'urls', data.urlId);
  // Atomically remove a collection from the "collections" array field.
  await updateDoc(urlRef, {
    collections: arrayRemove(data.listId),
  });
}

async function checkIsCollectionCoverInStore(owner, collectionId) {
  try {
    const snap = await getDoc(
      doc(db, 'users', owner, 'collections', collectionId)
    );
    const url = snap.data()?.cover;
    const host = new URL(url).host;
    return host === 'firebasestorage.googleapis.com';
  } catch (error) {
    return { status: 'error', code: error.code };
  }
}

// Create a new collection
// Used when state changes at C(R)UD
//TJO ANVÄNDS AV DnD
export function createCollection(data) {
  return setDoc(doc(db, 'users', data.owner, 'collections', data.listId), {
    ...data,
    owner: data.owner,
    timestamp: serverTimestamp(),
  });
}

export function useCollectionsByOwnerTJ(owner, a_z = false) {
  return useQuery(
    [{ a_z }, 'orderedCollections', { owner }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'collections'),
        a_z ? orderBy('title') : orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!owner }
  );
}
//TJO används när en ny kollektion skapas från kollektion sidan
//använder addDoc för att få autogenererat namn
export function createCollectionTJ(data) {
  return addDoc(collection(db, 'users', data.owner, 'collections'), {
    ...data,
    timestamp: serverTimestamp(),
  });
}

export function createCollectionWIthCustomIdTJ(data, id) {
  return setDoc(doc(collection(db, 'users', data.owner, 'collections'), id), {
    ...data,
    timestamp: serverTimestamp(),
  });
}

// Subscribe to item data
export function useCollectionTJ(owner, id) {
  return useQuery(
    ['collection', { id }],
    createQuery(() => doc(db, 'users', owner, 'collections', id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useCollectionOnceTJ(owner, id) {
  return useQuery(
    ['collection', { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, 'users', owner, 'collections', id)).then(format),
    { enabled: !!id }
  );
}

// Update an item
export function updateCollectionTJ(owner, id, shared, title, data) {
  //Update changedCollection to keep extension in sync
  const extensionRef = doc(
    db,
    'users',
    owner,
    'extSubscription',
    'collections'
  );
  const docData = {
    changedCollection: {
      value: id,
      shared: shared,
      label: title,
    },
    owner: owner,
  };
  setDoc(extensionRef, docData, { merge: true });
  return updateDoc(doc(db, 'users', owner, 'collections', id), {
    ...data,
    updatedAt: serverTimestamp(),
  });
}

//TJ FRÅGOR FÖR ATT FÅ ALLA URLS TILL EN KOLLEKTION PAGE
export function useUrlsTJ_ANVÄNDSEJ(owner, id) {
  return useQuery(
    ['urls', { owner }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'urls'),
        where('collections', 'array-contains', id)
      )
    ),
    { enabled: !!owner }
  );
}

export function useUrlsTJ(owner, id, filter = false, a_z = false) {
  // Unique query key: https://react-query.tanstack.com/guides/query-keys
  return useQuery(
    [{ filter }, { a_z }, { owner }, { id }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'urls'),
        where('collections', 'array-contains', id),
        filter
          ? where('offline', '==', filter)
          : a_z
          ? orderBy('title')
          : orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!owner }
  );
}
/* export function useUrlsTJNoFilter(owner, id, a_z = false) {
  // Unique query key: https://react-query.tanstack.com/guides/query-keys
  return useQuery(
    ['Nofilter', { a_z }, { owner }, { id }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'urls'),
        where('collections', 'array-contains', id),
        a_z ? orderBy('title') : orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!owner }
  );
}

export function useUrlsTJFilter(owner, id, a_z = false) {
  // Unique query key: https://react-query.tanstack.com/guides/query-keys
  return useQuery(
    ['filter', { a_z }, { owner }, { id }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'urls'),
        where('collections', 'array-contains', id),
        where('offline', '==', true),
        a_z ? orderBy('title') : orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!owner }
  );
} */

export function useUrls(owner, id) {
  return useQuery(
    ['urls', { owner }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'urls'),
        where('collections', 'array-contains', id)
      )
    ),
    { enabled: !!owner }
  );
}

export function useOfflinePageOnceTJ(owner, id) {
  return useQuery(
    ['offline', { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () =>
      getDoc(doc(db, 'users', owner, 'offlinePages', id)).then((result) => {
        const offlinePage = format(result);
        offlinePage.article_text = fflate.strFromU8(
          fflate.decompressSync(offlinePage?.article_text.toUint8Array())
        );
        return offlinePage;
      }),
    { enabled: !!id }
  );
}

export function getPageOnceTJnoHook(owner, id) {
  return getDoc(doc(db, 'users', owner, 'urls', id)).then(format);
}

export function usePageTJ(owner, id) {
  return useQuery(
    ['page', { id }],
    createQuery(() => doc(db, 'users', owner, 'urls', id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function usePageOnceTJ(owner, id) {
  return useQuery(
    ['page', { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, 'users', owner, 'urls', id)).then(format),
    { enabled: !!id }
  );
}

export function updatePageTJ(owner, id, data) {
  return updateDoc(doc(db, 'users', owner, 'urls', id), {
    ...data,
    updatedAt: serverTimestamp(),
  });
}

// Fetch item data once
export function useUrlsOnce(id) {
  return useQuery(
    ['urls', { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDocs(doc(db, 'items', id)).then(format),
    { enabled: !!id }
  );
}

export async function deleteCollectionAndUrls(data) {
  data.urlsToBeAffected.forEach((element) => {
    updateDoc(doc(db, 'users', data.owner, 'urls', element.id), {
      collections: arrayRemove(data.listId),
      updatedAt: serverTimestamp(),
    });
  });

  const bookmarkRef = doc(
    db,
    'users',
    data.owner,
    'bookmarkedShares',
    data.owner + data.listId
  );

  const bookmarkSnap = await getDoc(bookmarkRef);

  const isBookmarkExist = !!bookmarkSnap.data();

  if (isBookmarkExist) {
    await deleteDoc(bookmarkRef);
  }

  data.urlsToBeDeleted.forEach((element) => {
    deleteDoc(doc(db, 'users', data.owner, 'urls', element.id));
    deleteFilesFromFolder(`offlinePages/${data.owner}/${element.id}`);
  });

  await deleteFilesFromFolder(
    `users/${data.owner}/collections/${data.listId}/screenshots`
  );

  //Update changedCollection to keep extension in sync
  const extensionRef = doc(
    db,
    'users',
    data.owner,
    'extSubscription',
    'collections'
  );

  //Delete the collection
  await deleteDoc(doc(db, 'users', data.owner, 'collections', data.listId));

  let label = 'Unsorted';
  let shared = 'false';
  let value = 'Unsorted';

  if (data.firstExistCollection) {
    label = data.firstExistCollection.title;
    shared = !!data.firstExistCollection?.shared;
    value = data.firstExistCollection.id;
  }

  const docData = {
    deletedCollection: {
      value: data.listId,
      Unsorted: {
        label: label,
        shared: shared,
        value: value,
      },
    },
    owner: data.owner,
  };
  setDoc(extensionRef, docData, { merge: true });
}

export async function deleteCollectionAndUrls_batch_OVANSTÅENDE_ANVÄNDS(data) {
  // Get a new write batch
  const batch = writeBatch(db);
  data.urlsToBeAffected.forEach((element) =>
    batch.update(doc(db, 'users', data.owner, 'urls', element.id), {
      collections: arrayRemove(data.listId),
    })
  );
  data.urlsToBeDeleted.forEach((element) =>
    batch.delete(doc(db, 'users', data.owner, 'urls', element.id))
  );

  batch.delete(doc(db, 'users', data.owner, 'collections', data.listId));

  // Commit the batch
  await batch.commit();
}

/*
const users = await db.collection('users').get()

const batches = _.chunk(users.docs, 500).map(userDocs => {
    const batch = db.batch()
    userDocs.forEach(doc => {
        batch.set(doc.ref, { field: 'myNewValue' }, { merge: true })
    })
    return batch.commit()
})

await Promise.all(batches)
Just remember to add import * as _ from "lodash" at the top.

*/

// Delete a page
export function deleteUrl(owner, id) {
  return deleteDoc(doc(db, 'users', owner, 'urls', id));
}

// Delete a collection
export function deleteCollection(owner, id) {
  return deleteDoc(doc(db, 'users', owner, 'collections', id));
}

/**** TAGS ****/

export function useTagsByOwner_OLD(owner) {
  return useQuery(
    ['tags', { owner }],
    createQuery(() => doc(db, 'users', owner, 'tags', 'tags')),
    { enabled: !!owner }
  );
}

export function useTagsByOwner(owner) {
  return useQuery(
    ['tags2', { owner }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'tags')
        //, orderBy('tags')
      )
    ),
    { enabled: !!owner }
  );
}

export function usePagesFilteredByTagsByOwner(owner, tags) {
  // Unique query key: https://react-query.tanstack.com/guides/query-keys

  return useQuery(
    [{ tags }, { owner }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'urls'),
        where('tagLabels', 'array-contains-any', tags)
        //,orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!owner }
  );
}
/**** END OF TAGS ****/

/**** PAGESHARING ****/
/* export function usePageSharingUrlsTJ(owner) {
  // Unique query key: https://react-query.tanstack.com/guides/query-keys
  return useQuery(
    ['pageSharing', { owner }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'urls'),
        where('pageSharing', '==', true)
      )
    ),
    { enabled: !!owner }
  );
}

export async function removePageSharingTJ(data) {
  try {
    await updateDoc(doc(db, 'users', data.owner, 'urls', data.key), {
      pageSharing: false,
    });
    return 'success';
  } catch (error) {
    return { status: 'error', code: error.code };
  }
}
 */
/**** END OF PAGESHARING ****/

/**** SHARED ****/
export async function shareCollectionTJ(data) {
  try {
    //Mark collection as shared.
    await updateDoc(doc(db, 'users', data.owner, 'collections', data.id), {
      shared: true,
      updatedAt: serverTimestamp(),
    });

    //Update changedCollection to keep extension in sync
    const extensionRef = doc(
      db,
      'users',
      data.owner,
      'extSubscription',
      'collections'
    );
    const docData = {
      changedCollection: {
        value: data.id,
        shared: true,
        label: data.title,
      },
      owner: data.owner,
    };
    setDoc(extensionRef, docData, { merge: true });

    //Update all urls
    const q = query(
      collection(db, 'users', data.owner, 'urls'),
      where('collections', 'array-contains', data.id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      updateDoc(doc.ref, {
        shared: true,
        updatedAt: serverTimestamp(),
      });
    });

    return 'success';
  } catch (error) {
    return { status: 'error', code: error.code };
  }
}

async function checkCollectionsSharedStatus(data) {
  const asyncSome = async (arr, predicate) => {
    for (let e of arr) {
      if (await predicate(e)) return true;
    }
    return false;
  };

  const res = await asyncSome(data.collectionArray, async (i) => {
    const docRef = doc(db, 'users', data.owner, 'collections', i);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().shared === true;
    } else {
      // doc.data() will be undefined in this case
      //return false;
    }
    /* await sleep(3000);
    //return i % 2 === 0;
    return i === 3; */
  });

  // Checking 1
  // Checking 2

  // If result is true then this url is in another shared collection, leave it.
  // Otherwise this was the only shared collection so change to false
  if (res === false) {
    updateDoc(data.documentRef, {
      shared: false,
      updatedAt: serverTimestamp(),
    });
  }
  //Om res === true då finns url i en delad kollektion då ska flaggan så kvar dvs gör inget
  //om res false uppdatera, sätt flagga till false!
  //true
}

export async function unShareCollectionTJ(data) {
  try {
    //Mark collection as not shared.
    await updateDoc(doc(db, 'users', data.owner, 'collections', data.id), {
      shared: false,
      updatedAt: serverTimestamp(),
    });

    //Update changedCollection to keep extension in sync
    const extensionRef = doc(
      db,
      'users',
      data.owner,
      'extSubscription',
      'collections'
    );
    const docData = {
      changedCollection: {
        value: data.id,
        shared: false,
        label: data.title,
      },
      owner: data.owner,
    };
    setDoc(extensionRef, docData, { merge: true });

    //Find all urls in the collection
    const q = query(
      collection(db, 'users', data.owner, 'urls'),
      where('collections', 'array-contains', data.id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((res) => {
      //Bra MEN det betyder ju inte att den andra collection är delad.
      //Skriva in vid delning om arrayen är delad...

      if (res.data().collections.length === 1) {
        updateDoc(res.ref, {
          shared: false,
          updatedAt: serverTimestamp(),
        });
      } else {
        //Create new array minus the collection being unshared
        const filtered = res
          .data()
          .collections.filter((value) => value !== data.id);

        filtered.length > 0 &&
          checkCollectionsSharedStatus({
            collectionArray: filtered,
            documentRef: res.ref,
            owner: data.owner,
          });
      }
    });

    return 'success';
  } catch (error) {
    return { status: 'error', code: error.code };
  }
}

/* export async function shareCollectionTJ_OLD(data) {
  try {
    const newSharedCollectionRef = collection(
      db,
      'shared',
      data.owner,
      data.id
    );
    const batch = writeBatch(db);
    const q = query(
      collection(db, 'users', data.owner, 'urls'),
      where('collections', 'array-contains', data.id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docka) => {
      let tmp = docka.data();
      tmp.collections = [];
      tmp.tags = [];
      batch.set(doc(newSharedCollectionRef, docka.id), tmp);
    });
    await batch.commit();

    const collectionsRef = collection(db, 'shared', data.owner, 'collections');
    setDoc(doc(collectionsRef, data.id), {
      title: data.title,
      cover: data.cover,
      createdAt: serverTimestamp(),
    });
    return 'success';
  } catch (error) {
    return { status: 'error', code: error.code };
  }
} */

export function useSharedUrlsTJ(sharingUid, collectionId, a_z = false) {
  return useQuery(
    [{ a_z }, collectionId, { sharingUid }],
    createQuery(() =>
      query(
        collection(db, 'users', sharingUid, 'urls'),
        where('shared', '==', true),
        where('collections', 'array-contains', collectionId),
        a_z ? orderBy('title') : orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!sharingUid }
  );
}

export function useSharedDocumentsTJ(sharingUid, collectionId, documentIds) {
  return useQuery(
    ['sharedDocuments', sharingUid, collectionId, documentIds],
    async () => {
      if (!documentIds || documentIds.length === 0) {
        return [];
      }

      const documents = [];
      for (const docId of documentIds) {
        try {
          const docRef = doc(db, 'users', sharingUid, 'documents', docId);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            documents.push({ id: docSnapshot.id, ...docSnapshot.data() });
          } else {
            console.warn(`Document with ID ${docId} not found.`);
          }
        } catch (error) {
          console.error(`Error fetching document with ID ${docId}:`, error);
        }
      }
      return documents;
    },
    { enabled: !!sharingUid && !!documentIds }
  );
}

// export function useSharedDocumentsTJ(sharingUid, collectionId) {
//   const docRef = doc(db, 'users', sharingUid, 'documents', collectionId);
//   //const docRef = doc(db, 'users', sharingUid, 'collections', collectionId);
//   //const docSnap = await getDoc(docRef, where('shared', '==', true));
//   return useQuery(
//     ['sharedcollection', { sharingUid }],
//     createQuery(() => query(docRef), where('shared', '==', true)),
//     { enabled: !!sharingUid }
//   );
// }

//MÅNDAG nedanför

export async function checkBookmarkExist(owner, listId) {
  const bookmarkRef = doc(
    db,
    'users',
    owner,
    'bookmarkedShares',
    owner + listId
  );

  const bookmarkSnap = await getDoc(bookmarkRef);

  return !!bookmarkSnap.data();
}

export function useSharedCollectionTJ(sharingUid, collectionId) {
  const docRef = doc(db, 'users', sharingUid, 'collections', collectionId);
  //const docRef = doc(db, 'users', sharingUid, 'collections', collectionId);
  //const docSnap = await getDoc(docRef, where('shared', '==', true));
  return useQuery(
    ['sharedcollection', { sharingUid }],
    createQuery(() => query(docRef), where('shared', '==', true)),
    { enabled: !!sharingUid }
  );
}

export function createBookmark(data) {
  return setDoc(
    doc(db, 'users', data.owner, 'bookmarkedShares', data.owner + data.id),
    {
      id: data.id,
      sharingUid: data.sharingUid,
      title: data.title,
      cover: data.cover,
      owner: data.owner,
      timestamp: serverTimestamp(),
    }
  );
}

export async function updateBookmark(owner, id, data) {
  return updateDoc(doc(db, 'users', owner, 'bookmarkedShares', owner + id), {
    ...data,
    updatedAt: serverTimestamp(),
  });
}

// Delete a page
export function deleteBookmark(data) {
  return deleteDoc(
    doc(db, 'users', data.owner, 'bookmarkedShares', data.owner + data.id)
  );
}

export function useSharedBookmarksTJ(owner, a_z = false) {
  return useQuery(
    [{ a_z }, 'bookmarkedShares', { owner }],
    createQuery(() =>
      query(
        collection(db, 'users', owner, 'bookmarkedShares'),
        a_z ? orderBy('title') : orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!owner }
  );
}

export async function addPage(data) {
  try {
    let fullUrl;

    if (data.isAiResponse) {
      fullUrl = `${process.env.REACT_APP_PICKER_API_URL}/render/${data.owner}`;
    } else {
      fullUrl = data.url;
    }

    const payload = {
      collectionId: data.collectionId,
      ownerId: data.owner,
      url: fullUrl,
      title: data.urlTitle.slice(0, 200),
      image: data.image,
      articleText: markdownToHtml(data.text),
      isAiResponse: data.isAiResponse,
      runAsync: true,
    };
    const result = await createCollectionUrl(payload);
    return result;
  } catch (error) {
    return { status: 'error', code: error.code };
  }
}

/**** END OF SHARED ****/

/**** NOTIFICATIONS ****/

export function useNotifications(uid) {
  return useQuery(
    ['notifications', { uid }],
    createQuery(() =>
      query(
        collection(db, 'users', uid, 'notifications'),
        orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!uid }
  );
}

export function markNotificationAsRead(uid, notificationId) {
  return updateDoc(doc(db, 'users', uid, 'notifications', notificationId), {
    isRead: true,
  });
}

/**** END OF NOTIFICATIONS ****/

/**** DOCUMENTS ****/

export function useDocuments(uid) {
  return useQuery(
    ['documents', { uid }],
    createQuery(() =>
      query(
        collection(db, 'users', uid, 'documents'),
        orderBy('timestamp', 'desc')
      )
    ),
    { enabled: !!uid }
  );
}

export function updateDocuments(data) {
  const documentRef = doc(db, 'users', data.uid, 'documents', data.documentId);
  return updateDoc(documentRef, {
    name: data.documentName,
  });
}

export async function getBrainIdCollection(data) {
  const collectionRef = doc(
    db,
    'users',
    data.uid,
    'collections',
    data.collectionId
  );

  const collectionSnap = await getDoc(collectionRef);
  const collectionBrainId = collectionSnap.data()?.brainId;

  return collectionBrainId;
}
/**** END OF DOCUMENTS ****/

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === 'observerRemoved' &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

export function createAiResponse(data) {
  return addDoc(collection(db, 'users', data.owner, 'aiResponses'), {
    ...data,
    timestamp: serverTimestamp(),
  });
}

export async function createFeedback(data) {
  const collectionRef = collection(db, 'Pilot2025');
  const docRef = doc(collectionRef);
  const docId = docRef.id;
  let downloadTokensData;
  if (data.file) {
    const token = data.token;
    const { downloadTokens } = await uploadDataUrlFile(
      data.file,
      `users/${data.owner}/Pilot2025/${docRef.id}`,
      data.fileName,
      token
    );
    downloadTokensData = downloadTokens;
  }

  await setDoc(docRef, {
    uuid: docId,
    url: data.url,
    feedback: data.feedback,
    category: data.category,
    owner: data.owner,
    email: data.email,
    ...(data.file && {
      image: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/users%2F${data.owner}%2FPilot2025%2F${docId}%2F${data.fileName}?alt=media&token=${downloadTokensData}`,
    }),
    timestamp: serverTimestamp(),
  });

  await emailSender({
    email: data.email,
    type: data.category,
    url: data.url,
    feedback: data.feedback,
    ...(data.file && {
      image: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/users%2F${data.owner}%2FPilot2025%2F${docId}%2F${data.fileName}?alt=media&token=${downloadTokensData}`,
    }),
  });
}

export function addToWaitingList(data) {
  return addDoc(collection(db, 'WaitingList'), {
    ...data,
    timestamp: serverTimestamp(),
  });
}

export function addInfoRequests(data) {
  return addDoc(collection(db, 'InfoRequests'), {
    ...data,
    timestamp: serverTimestamp(),
  });
}
