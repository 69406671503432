import { useEffect, useRef } from 'react';
import { CHECK_SUBCRIBE_INTERVAL } from 'consts';
import { useAtom } from 'jotai';
import { limAndUsageInfoAtom } from 'store';
import { getUserLimitsAndUsage } from 'util/firebasefunctions';

const useCheckSub = (auth) => {
  const [, setLimAndUsageInfo] = useAtom(limAndUsageInfoAtom);

  const interval = useRef(null);

  useEffect(() => {
    if (auth) {
      const fetchSubscriptionAndLimits = async () => {
        try {
          // Fetch limits and usage data from the Firebase Function
          const limitsResult = await getUserLimitsAndUsage();

          if (limitsResult.data && limitsResult.data.success) {
            setLimAndUsageInfo({
              entitlement: limitsResult.data.entitlement,
              maxCollections: limitsResult.data.maxCollections,
              maxQuestions: limitsResult.data.maxQuestions,
              maxQuestionsResetTime: limitsResult.data.maxQuestionsResetTime,
              usedCollections: limitsResult.data.usedCollections,
              usedQuestions: limitsResult.data.usedQuestions,
            });
          } else {
            console.warn('Failed to retrieve user limits and usage.');
            return null;
          }

          return limitsResult.data;
        } catch (error) {
          console.error('Error fetching user limits and usage:', error);

          setLimAndUsageInfo(null);
          return null;
        }
      };

      (async () => {
        // For the first instant call
        const initialStatus = await fetchSubscriptionAndLimits();

        if (initialStatus === null) {
          console.warn(
            'Failed to retrieve user limits and usage on initial load.  Stopping subscription checks.'
          );
          clearInterval(interval.current);
          return;
        }

        interval.current = setInterval(async () => {
          if (auth) {
            const newData = await fetchSubscriptionAndLimits();
            if (!newData) {
              clearInterval(interval.current);
            }
          } else {
            clearInterval(interval.current);
            setLimAndUsageInfo(null);
          }
        }, CHECK_SUBCRIBE_INTERVAL);
      })();
    } else {
      setLimAndUsageInfo(null);
      clearInterval(interval.current);
    }
    return () => {
      setLimAndUsageInfo(null);
      clearInterval(interval.current);
    };
  }, [auth, setLimAndUsageInfo]);
};

export default useCheckSub;
