import { useEffect, useRef, useState } from 'react';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Box } from '@mui/material';
import { useAuth } from 'util/auth';
import { addPage, createCollectionTJ, useCollectionsByOwnerTJ } from 'util/db';
import { getBrainEnabledCollections } from 'util/firebasefunctions';

import CustomSnackbar from 'components/CustomSnackbar';

import ChatField from '../ChatField';
import CollectionSelector from '../CollectionSelector';
import MessageList from '../MessageList';
import * as ST from './styles';

import questionIcon from 'assets/icons/Qmark_B_bigger_white.svg';

const ChatBotWindow = ({
  messages,
  collectionId,
  isFullScreen,
  setFullScreen,
  handleSendRequest,
  scrollBoxRef,
  setIsError,
  setAlertMessage,
  setMessages,
  isLoading,
  currentUserMessage,
  setCurrentUserMessage,
  handleExtend,
  isExtended,
  setIsDraggerDisabled,
  collectionIdCustom,
  setCollectionIdCustom,
  setCollectionOwner,
  activeTab,
  setActiveTab,
  setSelectCollection,
  selectCollection,
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isPendingSave, setSavePending] = useState(false);
  const [isCollectionsLoading, setIsCollectionsLoading] = useState(false);
  const [isBrainCollectionsLoading, setIsBrainCollectionsLoading] =
    useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [brainOptions, setBrainOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const [selectedMode, setSelectedMode] = useState(activeTab); // 'AI chat' | 'Private AI'

  const auth = useAuth();

  const { data: items, status: itemsStatus } = useCollectionsByOwnerTJ(
    auth.user?.uid
  );

  const initialCollectionIdRef = useRef(collectionId);

  const handleModeChange = (mode) => {
    setSelectedMode(mode);
    setActiveTab(mode);
    handleClear();

    if (mode === 'Private AI') {
      fetchBrainCollections(true);
    }
  };

  const handleSaveClick = (i) => {
    if (!selectCollection) {
      setNotification({
        message: 'Select or create new collection – to save answer',
        type: 'error',
      });

      setOpenSnackbar(true);
    } else {
      setCurrentMessageIndex(i);
      setSavePending(true);

      const previousCollection = selectCollection;

      try {
        addPage({
          owner: auth.user.uid,
          urlTitle: messages[i - 1].content,
          text: messages[i].content,
          collectionId: selectCollection.value,
          image: `${process.env.PUBLIC_URL}/images/ai.png`,
          isAiResponse: true,
        }).then(() => {
          const messagesCopy = [...messages];
          messagesCopy[i].saved = true;
          setMessages(messagesCopy);
          setSavePending(false);

          setSelectCollection(previousCollection);
        });
      } catch (e) {
        setNotification({
          message: `${e.message}`,
          type: 'error',
        });
      }
    }
  };

  const handleClear = () => {
    setMessages([]);
    setCurrentUserMessage('');
    setSelectCollection('');
    setCollectionIdCustom('');
    setCollectionOwner('');
  };

  const handleChange = (newValue) => {
    setSelectCollection(newValue);
    setCollectionIdCustom(newValue?.value || '');
    setCollectionOwner(newValue?.owner || '');
  };

  const handleCreate = (inputValue) => {
    setIsCollectionsLoading(true);
    const shouldSetValue = inputValue.length <= 50;
    if (shouldSetValue) {
      createCollectionTJ({
        title: inputValue,
        owner: auth.user.uid,
        shared: false,
      })
        .then((res) => {
          const newCollection = {
            label: inputValue,
            value: res.id,
            shared: false,
          };
          setSelectCollection(newCollection);
          setCollectionIdCustom(res.id);
        })
        .finally(() => {
          setIsCollectionsLoading(false);
        });
    } else {
      alert('Collection Title is too long (max 50 characters)');
      setIsCollectionsLoading(false);
    }
  };

  const fetchBrainCollections = async (includeShared) => {
    setIsBrainCollectionsLoading(true);

    try {
      const brainEnabledCollectionsData = await getBrainEnabledCollections({
        includeSharedCollections: includeShared,
      });

      if (brainEnabledCollectionsData?.data) {
        const brainData = brainEnabledCollectionsData.data.map((item) => ({
          label: item.title,
          value: item.id,
          shared: item.shared,
          owner: item.owner,
        }));
        setBrainOptions(brainData);
      } else {
        setBrainOptions([]);
      }
    } catch (error) {
      setAlertMessage('Failed to get brain enabled collections');
      console.warn(error);
    } finally {
      setIsBrainCollectionsLoading(false);
    }
  };

  const handleCheckSendRequest = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (selectedMode !== 'Private AI' && collectionIdCustom) {
      setCollectionIdCustom('');
      setCollectionOwner('');
      return;
    }

    if (selectedMode === 'Private AI' && !selectCollection) {
      setNotification({
        message: 'Please select a Private AI before starting a conversation.',
        type: 'error',
      });
      setOpenSnackbar(true);
      return;
    } else {
      await handleSendRequest();
    }
  };

  useEffect(() => {
    if (itemsStatus !== 'loading' && items) {
      let data = [];

      items.forEach((item) => {
        data.push({
          label: item.title,
          value: item.id,
          shared: item.shared,
        });
      });

      setOptions(data);
      setIsCollectionsLoading(false);
    } else {
      setIsCollectionsLoading(true);
    }
  }, [items, itemsStatus]);

  useEffect(() => {
    if (collectionId && collectionId !== initialCollectionIdRef.current) {
      let collectionToSelect = null;

      if (selectedMode === 'Private AI') {
        collectionToSelect = brainOptions.find(
          (option) => option.value === collectionId
        );
      } else {
        collectionToSelect = options.find(
          (option) => option.value === collectionId
        );
      }

      if (collectionToSelect) {
        setSelectCollection(collectionToSelect);
      }
      initialCollectionIdRef.current = collectionId;
    }
  }, [collectionId, itemsStatus, options, brainOptions, selectedMode]);

  useEffect(() => {
    if (selectedMode === 'Private AI') {
      const foundBrainCollection = brainOptions.find(
        (option) => option.value === collectionId
      );

      if (foundBrainCollection) {
        setSelectCollection(foundBrainCollection);
        setCollectionOwner(foundBrainCollection.owner);
      }
    } else {
      const foundCollection = options.find(
        (option) => option.value === collectionId
      );
      if (foundCollection) {
        setSelectCollection(foundCollection);
      }
    }
  }, [selectedMode, brainOptions, options, collectionId]);

  useEffect(() => {
    if (selectedMode === 'Private AI') {
      if (collectionId && collectionId !== collectionIdCustom) {
        setCollectionIdCustom(collectionId);
      }
    } else {
      setCollectionIdCustom('');
    }
  }, [collectionId, selectedMode]);

  useEffect(() => {
    if (activeTab === 'Private AI') {
      fetchBrainCollections(true);
    }
  }, []);

  const renderCollectionSelector = () => (
    <CollectionSelector
      selectedMode={selectedMode}
      isCollectionsLoading={isCollectionsLoading}
      options={selectedMode === 'Private AI' ? brainOptions : options}
      selectCollection={selectCollection}
      handleChange={handleChange}
      handleCreate={handleCreate}
      isFullScreen={isFullScreen}
      isExtended={isExtended}
      isBrainCollectionsLoading={isBrainCollectionsLoading}
      placeholder={
        selectedMode === 'Private AI'
          ? 'Select Private AI'
          : 'Select Collection'
      }
    />
  );

  return (
    <ST.StyledChatBox mx="auto" p={0.3} onDoubleClick={handleExtend}>
      <Box display="flex" alignItems="center">
        <ST.StyledIcon src={questionIcon} alt="question" />
        <Box ml={'14px'} sx={{ display: 'flex' }}>
          <ST.StyledModeButton
            isSelected={activeTab === 'AI chat'}
            onClick={() => handleModeChange('AI chat')}
          >
            AI chat
          </ST.StyledModeButton>
          <ST.StyledModeButton
            isSelected={activeTab === 'Private AI'}
            onClick={() => handleModeChange('Private AI')}
          >
            Private AI
          </ST.StyledModeButton>
        </Box>
        <ST.StyledButton
          startIcon={
            isFullScreen ? <CloseFullscreenIcon /> : <FullscreenIcon />
          }
          onClick={() => setFullScreen(!isFullScreen)}
        />
      </Box>
      <ST.DividedLine />

      {selectedMode === 'Private AI' && (
        <Box sx={{ marginTop: '10px' }}>{renderCollectionSelector()}</Box>
      )}

      <Box
        display="flex"
        flexDirection="column"
        mt={1}
        position="relative"
        flexGrow={2}
        height={isFullScreen ? '200px' : 'unset'}
      >
        <MessageList
          messages={messages}
          scrollBoxRef={scrollBoxRef}
          isFullScreen={isFullScreen}
          handleSaveClick={handleSaveClick}
          isPendingSave={isPendingSave}
          currentMessageIndex={currentMessageIndex}
        />

        {isLoading && <ST.LoadingProgress />}

        <ChatField
          isLoading={isLoading}
          selectedMode={selectedMode}
          currentUserMessage={currentUserMessage}
          setCurrentUserMessage={setCurrentUserMessage}
          handleSendRequest={handleCheckSendRequest}
          setIsDraggerDisabled={setIsDraggerDisabled}
        />
      </Box>

      <ST.StyledActionBox isFullScreen={isFullScreen}>
        <ST.StyledResetButton
          variant="outlined"
          isFullScreen={isFullScreen}
          onClick={handleClear}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          {selectedMode === 'AI chat' ? 'Start new Chat' : 'Start new session'}
        </ST.StyledResetButton>
        {selectedMode === 'AI chat' && renderCollectionSelector()}
      </ST.StyledActionBox>
      {notification !== null && (
        <CustomSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
            setNotification(null);
          }}
          notification={notification}
        />
      )}
    </ST.StyledChatBox>
  );
};

export default ChatBotWindow;
