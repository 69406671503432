import { useEffect, useRef, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { PATHS } from 'AppPaths';
import { getAuth } from 'firebase/auth';
import { useAtom } from 'jotai';
import PaywallExplanationModal from 'pages/paywall/PaywallExplanationModal';
import { collectionIdAtom, limAndUsageInfoAtom } from 'store';
import { aiBotRequestTrack } from 'util/analyticsHandlers';
import { requireSub } from 'util/auth.js';
import { firebaseApp } from 'util/firebase';
import { history } from 'util/router';

import CustomSnackbar from 'components/CustomSnackbar';

import ChatBotContent from './ChatBotContent';

const auth = getAuth(firebaseApp);
const ChatBot = ({ isFullScreen, setFullScreen }) => {
  const [limAndUsageInfo] = useAtom(limAndUsageInfoAtom);
  const [collectionId] = useAtom(collectionIdAtom);

  const [currentUserMessage, setCurrentUserMessage] = useState('');
  const [collectionIdCustom, setCollectionIdCustom] = useState('');
  const [collectionOwner, setCollectionOwner] = useState('');
  const [activeTab, setActiveTab] = useState('AI chat');
  const [selectCollection, setSelectCollection] = useState('');

  const [messages, setMessages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [notification, setNotification] = useState(null);
  const scrollBoxRef = useRef(null);

  useEffect(() => {
    if (showLimitModal) {
      history.replace(PATHS.PAYWALL);
    }
  }, [showLimitModal, history]);

  const processMessageToChatGPT = async (
    chatMessages,
    streamEnabled = false
  ) => {
    setIsLoading(true);
    const accessToken = auth.currentUser
      ? await auth.currentUser.getIdToken()
      : undefined;

    try {
      const message =
        chatMessages.length > 0
          ? [{ text: chatMessages[chatMessages.length - 1].content }]
          : [];
      const requestBody = JSON.stringify({
        data: {
          owner: collectionOwner,
          history: chatMessages,
          message: message,
          collectionId: collectionIdCustom,
          stream: streamEnabled,
        },
      });

      const response = await fetch(
        `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/queryOpenAICollectionBrainStream`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: requestBody,
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          'API call failed with error:',
          response.status,
          errorText
        );
        throw new Error(`HTTP error! status: ${response.status}, ${errorText}`);
      }

      if (streamEnabled) {
        // Handle stream response
        const reader = response.body.getReader();
        // let text = '';
        let fullResponse = '';

        const handlePartialResponse = (partialResponse) => {
          setMessages((prevMessages) => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage?.role === 'assistant' && lastMessage?.isStreaming) {
              const updatedLastMessage = {
                ...lastMessage,
                content: partialResponse,
              };
              return [...prevMessages.slice(0, -1), updatedLastMessage];
            } else {
              return [
                ...prevMessages,
                {
                  content: partialResponse,
                  role: 'assistant',
                  saved: false,
                  isStreaming: true,
                },
              ];
            }
          });
        };

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const decodedText = new TextDecoder().decode(value);

          const events = decodedText
            .split('\n\n')
            .filter((event) => event !== '');
          for (const event of events) {
            const data = event.substring(5);
            try {
              const json = JSON.parse(data);
              if (json.content) {
                fullResponse += json.content;
                // Update UI with partial response
                handlePartialResponse(fullResponse);
              }
            } catch (e) {
              console.error('Couldnt parse json', data, e);
            }
          }
        }
        return { content: fullResponse };
      } else {
        // Handle normal response
        const data = await response.json();
        if (data && data.message && data.message.content) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              content: data.message.content,
              role: 'assistant',
              saved: false,
            },
          ]);
          return { content: data.message.content };
        } else {
          throw new Error('Unexpected response format from OpenAI API.');
        }
      }
    } catch (error) {
      setIsError(true);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (
      limAndUsageInfo &&
      limAndUsageInfo.entitlement === 'free' &&
      limAndUsageInfo.usedQuestions >= limAndUsageInfo.maxQuestions
    ) {
      setShowLimitModal(true);
      return;
    }

    aiBotRequestTrack();

    if (currentUserMessage !== '') {
      try {
        const updatedMessages = [
          ...messages,
          {
            content: currentUserMessage,
            role: 'user',
          },
        ];

        setMessages(updatedMessages);

        const { content } = await processMessageToChatGPT(
          updatedMessages.map((message) => ({
            role: message.role,
            content: message.content,
          })),
          true
        );

        const askMessage = {
          content: currentUserMessage,
          role: 'user',
        };

        if (content) {
          const answerMessage = {
            content,
            role: 'assistant',
            saved: false,
            isStreaming: false,
          };
          setMessages([...messages, askMessage, answerMessage]);
        }
        setCurrentUserMessage('');
      } catch (e) {
        const errorMessage = e.message
          .replace(/HTTP error! status: 500, /, '')
          .trim();

        setNotification({
          message: `${errorMessage} You can continue working with Picker.`,
          type: 'error',
        });

        setOpenSnackbar(true);
      }

      scrollBoxRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const propsToPass = {
    isFullScreen,
    setFullScreen,
    currentUserMessage,
    setCurrentUserMessage,
    setCollectionOwner,
    messages,
    handleSendRequest: handleSubmit,
    isLoading,
    setIsError,
    isError,
    handleCloseError: () => setIsError(false),
    setMessages,
    scrollBoxRef,
    collectionIdCustom,
    setCollectionIdCustom,
    collectionId,
    activeTab,
    setActiveTab,
    setSelectCollection,
    selectCollection,
  };

  return (
    <>
      {showLimitModal && (
        <PaywallExplanationModal
          open={showLimitModal}
          reason="You've reached your daily limit for AI questions. Upgrade now or wait until tomorrow for a new quota."
          onClose={() => setShowLimitModal(false)}
        />
      )}
      {!isFullScreen ? (
        <Box width="96%" mx="auto">
          <ChatBotContent {...propsToPass} />
        </Box>
      ) : (
        <Modal open={isFullScreen} onClose={() => setFullScreen(false)}>
          <ChatBotContent {...propsToPass} />
        </Modal>
      )}
      {notification !== null && (
        <CustomSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
            setNotification(null);
          }}
          notification={notification}
        />
      )}
    </>
  );
};

export default requireSub(ChatBot);
