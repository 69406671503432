import { useEffect, useState } from 'react';
import { useAuth } from 'util/auth';
import { markNotificationAsRead, useNotifications } from 'util/db';

const useNotificationsManager = () => {
  const auth = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notification, setNotification] = useState(null);
  const { data: notifications } = useNotifications(auth?.user?.uid);

  const handleCloseSnackbar = async (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    if (notification && !notification.isRead) {
      markNotificationAsRead(auth.user.uid, notification.id);
      setNotification(null);
    }
  };

  useEffect(() => {
    if (notifications) {
      const unreadNotification = notifications.find(
        (notification) => !notification.isRead
      );
      if (unreadNotification) {
        setNotification(unreadNotification);
        setOpenSnackbar(true);
      } else {
        setOpenSnackbar(false);
        setNotification(null);
      }
    } else {
      setOpenSnackbar(false);
      setNotification(null);
    }
  }, [notifications]);

  const showNewNotification = (newNotification) => {
    setNotification(newNotification);
    setOpenSnackbar(true);
  };

  return {
    openSnackbar,
    notification,
    handleCloseSnackbar,
    showNewNotification,
  };
};

export default useNotificationsManager;
