import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChatFieldBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen' && prop !== 'isActive',
})(({ isActive, isFullScreen }) => ({
  backgroundColor: 'white',
  borderRadius: '10px',
  minHeight: isFullScreen ? 'unset' : '150px',
  maxHeight: isFullScreen ? '100%' : '230px',
  marginBottom: '20px',
  color: 'grey',
  display: 'flex',
  justifyContent: isActive ? 'center' : 'unset',
  flexDirection: 'column',
  alignItems: 'left',
  textAlign: 'center',
  overflow: 'auto',
  fontSize: isFullScreen ? '14px' : '11px',
  height: '100%',
}));

export const MessageBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUser',
})(({ isUser }) => ({
  fontSize: '0.9rem',
  borderRadius: '10px',
  padding: '8px 10px',
  width: 'max-content',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  backgroundColor: isUser ? 'black' : '#BABABA',
  color: isUser ? 'white' : 'black',
  boxShadow: '0 0 5px blue',
  marginBottom: '10px',
  marginRight: isUser && '10px',
  maxWidth: isUser ? 'calc(100% - 30px)' : '100%',
  textAlign: 'left',
  overflowX: 'clip',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  '&:first-of-type': {
    marginTop: '10px',
  },
  '& p': {
    margin: 0,
  },
}));

export const ContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})(({ isFullScreen }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 'max-content',
  margin: '0 10px 10px',
  width: isFullScreen ? 'calc(100% - 90px)' : 'calc(100% - 30px)',
  alignItems: 'flex-end',
}));

export const StyledSaveButton = styled(Button)({
  color: '#6598cb',
  padding: 0,
  display: 'flex',
  alignSelf: 'flex-end',
  fontSize: '10px',
});
