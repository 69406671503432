import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/material';

import {
  AlertLayout,
  CloseIconButton,
  MessageTitle,
  TextBox,
  TypeTitle,
} from './style';

const getAlertStyles = (type) => {
  switch (type) {
    case 'success':
      return { background: '#E6F4EA', color: '#1E7E34', iconColor: '#28A745' };
    case 'info':
      return { background: '#E7F3FF', color: '#0C5460', iconColor: '#007BFF' };
    case 'warning':
      return { background: '#FFF3CD', color: '#856404', iconColor: '#FFC107' };
    case 'error':
      return { background: '#F8D7DA', color: '#721C24', iconColor: '#DC3545' };
    default:
      return { background: '#FFFFFF', color: '#333', iconColor: '#000' };
  }
};
const CustomSnackbar = ({ open, onClose, notification }) => {
  const { message, type } = notification || {};

  const allTypes = notification.type === 'notification' ? 'success' : type;

  const styles = getAlertStyles(allTypes);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
    >
      <AlertLayout
        variant="filled"
        severity={allTypes}
        customColors={styles}
        action={
          <CloseIconButton size="small" onClick={onClose} customColors={styles}>
            <CloseIcon fontSize="small" />
          </CloseIconButton>
        }
      >
        <TextBox>
          <TypeTitle>{type}</TypeTitle>
          <MessageTitle variant="subtitle1">{message}</MessageTitle>
        </TextBox>
      </AlertLayout>
    </Snackbar>
  );
};

export default CustomSnackbar;
