import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const PaywallExplanationModal = ({ open, reason, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Subscription Limit Reached</DialogTitle>
      <DialogContent>{reason}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaywallExplanationModal;
