import { Alert, AlertTitle, Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AlertLayout = styled(Alert, {
  shouldForwardProp: (prop) => prop !== 'customColors',
})(({ customColors }) => ({
  backgroundColor: customColors.background,
  color: customColors.color,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderRadius: '4px',
  borderLeft: `3px solid ${customColors.iconColor}`,
  padding: '3px 16px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
}));

export const CloseIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'customColors',
})(({ customColors }) => ({
  color: customColors.color,
  display: 'flex',
  alignItems: 'center',
}));

export const TextBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

export const TypeTitle = styled(AlertTitle)(() => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  textTransform: 'capitalize',
}));

export const MessageTitle = styled(Typography)(() => ({
  color: '#555',
  fontSize: '0.9rem',
}));
