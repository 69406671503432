import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { useAtom } from 'jotai';
import Drawer from 'layout/Drawer';
import Navbar from 'layout/Navbar';
import {
  headerHeightAtom,
  isDrawerOpenAtom,
  isVerificationOpenAtom,
} from 'store';
import { AuthProvider } from 'util/auth';
import { QueryClientProvider } from 'util/db';
import MetaPixel from 'util/meta/metaPixel';
import Rewardful from 'util/rewardful/rewardful';
import { Router } from 'util/router';
import { ThemeProvider } from 'util/theme';

import { MainContainer } from './Main';

const drawerWidth = 240;

const App = () => {
  const [isSurferOpen, setIsSurferOpen] = useState(false);

  const [isVerificationOpen, setIsVerificationOpen] = useAtom(
    isVerificationOpenAtom
  );
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);
  const [headerHeight] = useAtom(headerHeightAtom);

  const ToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <MetaPixel />
          <Rewardful />
          <Router>
            <>
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar
                  color="default"
                  open={isDrawerOpen}
                  ToggleDrawer={ToggleDrawer}
                  drawerWidth={drawerWidth}
                  setIsSurferOpen={setIsSurferOpen}
                />
                <Drawer
                  open={isDrawerOpen}
                  ToggleDrawer={ToggleDrawer}
                  drawerWidth={drawerWidth}
                />
                <MainContainer
                  isDrawerOpen={isDrawerOpen}
                  isVerificationOpen={isVerificationOpen}
                  isSurferOpen={isSurferOpen}
                  drawerWidth={drawerWidth}
                  headerHeight={headerHeight}
                  setIsVerificationOpen={setIsVerificationOpen}
                  setIsSurferOpen={setIsSurferOpen}
                />
              </Box>
            </>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
