import SendIcon from '@mui/icons-material/Send';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledQuestionField = styled(TextField)({
  backgroundColor: 'white',
  borderRadius: '10px',
  marginBottom: '15px',
  width: '100%',
  '& .MuiInputBase-input': {
    paddingTop: '6px',
    paddingBottom: '6px',
    color: 'black',
    '&::placeholder': {
      opacity: 1,
      color: 'grey',
      fontSize: '16px',
    },
  },
  '&::placeholder': {
    color: 'grey',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const StyledButton = styled(Button)({
  minWidth: '0',
  display: 'block',
  height: '20px',
  marginLeft: 'auto',
  padding: 0,
  cursor: 'pointer',
  '& .MuiButton-startIcon': {
    margin: 0,
    color: 'white',
  },
});

export const StyledSendIcon = styled(SendIcon)({
  color: '#6598cb',
});
