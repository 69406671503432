import { Button, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (props) => props !== 'drawerWidth',
})(({ drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
}));

export const FeedbackButton = styled(Button)({
  color: 'white',
  backgroundColor: '#ff8300',
  marginTop: '20px',
  marginLeft: '5px',
  marginRight: '5px',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: '#b35c00',
  },
});
