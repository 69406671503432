import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Typography,
} from '@mui/material';
import { useAuth } from 'util/auth';
import { createFeedback } from 'util/db';
import { getDataUrlFromFile } from 'util/files';

import * as ST from './styles';

const feedbackTypes = ['Bug', 'Error', 'Content', 'Graphic Design', 'Other'];

const FeedbackModal = ({ open, handleClose }) => {
  const auth = useAuth();

  const [isSended, setIsSended] = useState(false);
  const [pending, setPending] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: feedbackTypes[0],
      url: '',
      feedback: '',
    },
  });

  useEffect(() => {
    setValue('type', feedbackTypes[0]);
  }, [setValue]);

  useEffect(() => {
    if (open) {
      setValue('url', window.location.href);
    }
  }, [open, setValue]);

  const arrayOfErrors = Object.keys(errors).map((key) => ({
    field: key,
    ...errors[key],
  }));

  const onClose = () => {
    setIsSended(false);
    handleClose();
  };

  const onSubmit = async (data) => {
    setPending(true);
    const { url, feedback, type, image } = data;
    let file;
    let dataUrl;

    if (image.length > 0) {
      file = image[0];
      dataUrl = await getDataUrlFromFile(file);
    }

    try {
      await createFeedback({
        url,
        feedback,
        category: type,
        ...(image.length > 0 && {
          file: dataUrl,
          fileName: file.name,
          token: auth.user?.stsTokenManager?.accessToken,
        }),
        owner: auth.user.uid,
        email: auth.user.email,
      });
      setPending(false);
      setIsSended(true);
      reset();
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ST.ModalContent isSended={isSended}>
        {!isSended ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid white"
              alignItems="flex-end"
              width="100%"
              px={5}
              py={2}
            >
              <Typography variant="h4">Pilot-test Feedback</Typography>
              <Typography variant="subtitle2" sx={{ fontSize: '1.3rem' }}>
                Contact & Support:{' '}
                <ST.CustomLink href="mailto:support@mypicker.net">
                  support@mypicker.net
                </ST.CustomLink>
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                px={1}
                pt={2}
                pb={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
              >
                <Box maxWidth="300px" width="100%" position="relative" p={2}>
                  <FormControl fullWidth>
                    <InputLabel />
                    <ST.StyledSelect
                      defaultValue={feedbackTypes[0]}
                      displayEmpty
                      {...register('type', { required: 'Please enter type' })}
                      renderValue={(selected) => {
                        if (!selected || !feedbackTypes.includes(selected)) {
                          return (
                            <Typography sx={{ color: 'gray' }}>
                              Select from list
                            </Typography>
                          );
                        }
                        return selected;
                      }}
                      inputProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              color: 'black',
                              backgroundColor: 'white',
                            },
                          },
                        },
                      }}
                      sx={{
                        '& .MuiSelect-icon': { display: 'none' },
                        appearance: 'none',
                      }}
                      size="small"
                      id="select-feedback"
                      label=""
                    >
                      {feedbackTypes.map((type, i) => (
                        <MenuItem key={i} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </ST.StyledSelect>
                  </FormControl>
                  <ST.StyledLabelBox position="absolute">
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: '1.3rem', marginRight: 4 }}
                    >
                      Type of Feedback:
                    </Typography>
                  </ST.StyledLabelBox>
                </Box>
                {arrayOfErrors.length > 0 && (
                  <Alert severity="error">{arrayOfErrors[0].message}</Alert>
                )}
                <FormControl fullWidth>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <ST.FeedbackTextField
                      placeholder="Pre-populate this window with URL from where the user is when they open this window"
                      InputLabelProps={{ shrink: false }}
                      {...register('url', {
                        required: 'Please enter URL',
                        maxLength: {
                          value: 2048,
                          message: 'Maximum character size reached',
                        },
                      })}
                    />
                    <ST.FeedbackTextField
                      multiline
                      rows={10}
                      placeholder="Write your feedback here."
                      InputLabelProps={{ shrink: false }}
                      {...register('feedback', {
                        required: 'Please enter your feedback',
                        maxLength: {
                          value: 3000,
                          message: 'Maximum character size reached',
                        },
                      })}
                    />
                  </Box>
                </FormControl>
                <input
                  accept="image/*"
                  type="file"
                  {...register('image', {
                    validate: (value) => {
                      if (value && value.length > 0) {
                        return (
                          value[0].size <= 3000000 ||
                          'Oups - The image is too big. Please adjust image size to maximum 3 MB'
                        );
                      }
                      return true;
                    },
                  })}
                  id="screenshot"
                  style={{ display: 'none' }}
                />

                <ST.StyledButton
                  bgColor="#ff6f00"
                  htmlFor="screenshot"
                  component="label"
                  variant="contained"
                >
                  Upload a Screenshot
                </ST.StyledButton>
                <ST.StyledButton
                  bgColor="#2e99f7"
                  type="submit"
                  variant="contained"
                  disabled={pending}
                >
                  {pending ? <CircularProgress size={28} /> : 'Send Feedback'}
                </ST.StyledButton>
              </Box>
            </form>
          </>
        ) : (
          <Box>
            <Box
              p={3}
              pt={8}
              pb={8}
              border="2px solid white"
              textAlign="center"
            >
              <Typography variant="h6">Thanks for your feedback</Typography>
              {/* <Typography variant="body1" fontWeight="bold" mb={3}>
                It's much appreciated!
              </Typography>
              <Typography variant="body1" fontWeight="light">
                Your Picker team
              </Typography> */}
            </Box>
          </Box>
        )}
      </ST.ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
