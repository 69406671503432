import React from 'react';
import { InputAdornment } from '@mui/material';

import * as ST from './styles';

const ChatField = ({
  isLoading,
  selectedMode,
  currentUserMessage,
  setCurrentUserMessage,
  handleSendRequest,
  setIsDraggerDisabled,
}) => {
  return (
    <form onSubmit={handleSendRequest}>
      <ST.StyledQuestionField
        disabled={isLoading}
        placeholder={
          selectedMode === 'AI chat'
            ? 'Type your question...'
            : 'Write your prompt...'
        }
        value={currentUserMessage}
        onChange={(e) => {
          setCurrentUserMessage(e.target.value);
        }}
        onDoubleClick={(e) => e.stopPropagation()}
        onMouseEnter={() => setIsDraggerDisabled(true)}
        onMouseLeave={() => setIsDraggerDisabled(false)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ST.StyledButton
                type="submit"
                startIcon={<ST.StyledSendIcon />}
              />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default ChatField;
