import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, Typography } from '@mui/material';
import { markdownToHtml } from 'util/files';

import * as ST from './styles';

const MessageList = ({
  messages,
  scrollBoxRef,
  isFullScreen,
  handleSaveClick,
  isPendingSave,
  currentMessageIndex,
}) => {
  return (
    <ST.ChatFieldBox
      isActive={messages.length === 0}
      isFullScreen={isFullScreen}
    >
      {messages.length === 0 ? (
        <Typography variant="subtitle1">
          This is the answer area Ask your question below
        </Typography>
      ) : (
        messages.map((message, i) =>
          message.role === 'user' ? (
            <ST.MessageBox key={i} ref={scrollBoxRef} isUser>
              {message.content}
            </ST.MessageBox>
          ) : (
            <ST.ContentBox key={i} isFullScreen={isFullScreen}>
              <ST.MessageBox
                ref={scrollBoxRef}
                isUser={false}
                dangerouslySetInnerHTML={{
                  __html: markdownToHtml(message.content),
                }}
              />
              {!message.saved &&
                (isPendingSave && currentMessageIndex === i ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <ST.StyledSaveButton onClick={() => handleSaveClick(i)}>
                    <Typography
                      fontSize={12}
                      onDoubleClick={(e) => e.stopPropagation()}
                    >
                      Save answer
                    </Typography>
                    <DownloadIcon />
                  </ST.StyledSaveButton>
                ))}
            </ST.ContentBox>
          )
        )
      )}
    </ST.ChatFieldBox>
  );
};

export default MessageList;
