import styled from '@emotion/styled';
import { Box, Button, Link, Select, TextField } from '@mui/material';

export const ModalContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSended',
})(({ isSended }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background:
    'linear-gradient(180deg, rgba(255, 111, 0, 1) 0%, rgba(255, 171, 0, 1) 100%)',
  maxWidth: !isSended ? '980px' : '350px',
  width: 'calc(100% - 100px)',
  color: 'white',
  padding: isSended && '12px',
}));

export const StyledLabelBox = styled(Box)({
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '-25%',
});

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})(({ bgColor }) => ({
  color: 'white',
  backgroundColor: bgColor,
  width: '260px',
  border: '3px solid #ffffff',
  borderRadius: '10px',
  height: '38.5px',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: bgColor === '#ff6f00' ? '#d65c00' : '#257fcc',
  },
}));

export const StyledSelect = styled(Select)({
  background: 'white',
  color: 'black',
  '& .MuiFormLabel-root': {
    color: 'grey',
  },
});

export const FeedbackTextField = styled(TextField)({
  background: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    color: 'black',
  },
  '& .MuiInputLabel-root': {
    color: 'grey',
  },
});

export const CustomLink = styled(Link)({
  color: 'white',
  textDecoration: 'underline solid white 2px',
  textUnderlineOffset: '4px',
});
