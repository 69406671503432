import CookieBanner from 'react-cookie-banner';
import Footer from 'layout/Footer';
import VerificationModal from 'layout/VerificationModal';
import Routes from 'routes';
import useNotificationsManager from 'util/notifications';

import CustomCookieBanner from 'components/CustomCookieBanner/CustomCookieBanner';
import CustomSnackbar from 'components/CustomSnackbar';
import DrawerHeader from 'components/DrawerHeader';

import SurferBuddyModal from '../Banner/SurferBuddyModal';
import { Main } from './styles';

export const MainContainer = ({
  isDrawerOpen,
  isVerificationOpen,
  isSurferOpen,
  drawerWidth,
  headerHeight,
  setIsVerificationOpen,
  setIsSurferOpen,
}) => {
  const { openSnackbar, notification, handleCloseSnackbar } =
    useNotificationsManager();

  return (
    <Main open={isDrawerOpen} drawerWidth={drawerWidth}>
      <DrawerHeader height={headerHeight} />
      <Routes />
      <Footer />
      <CookieBanner dismissOnScroll={false} dismissOnClick={false}>
        {(onAccept) => <CustomCookieBanner onAccept={onAccept} />}
      </CookieBanner>
      <VerificationModal
        open={isVerificationOpen}
        handleClose={() => setIsVerificationOpen(false)}
      />
      <SurferBuddyModal
        open={isSurferOpen}
        handleClose={() => setIsSurferOpen(false)}
      />
      {notification !== null && (
        <CustomSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          notification={notification}
        />
      )}
    </Main>
  );
};
