import { Grid, Typography } from '@mui/material';
import { PATHS } from 'AppPaths';
import { Link } from 'util/router';
import { useDarkMode } from 'util/theme';

import SectionHeader from 'components/SectionHeader';

import Auth from '../AuthSection/Auth';
import { StyledLink } from './styles';

import PickerTagLogo from 'assets/icons/PickerLogoTagB02';

// Options by auth type
const optionsByType = {
  signup: {
    // Top Title
    title: 'Sign up to start using Picker',
    // Button text
    buttonAction: 'Continue',
    // Footer text and links
    showFooter: true,
    text: 'Already have an account?',
    linkText: 'Log in here',
    signinAction: 'Sign in',
    contextPath: PATHS.AUTH('signin'),
    // Terms and privacy policy agreement
    showAgreement: true,
    termsPath: PATHS.TERMS_AND_PRIVACY,
    privacyPolicyPath: PATHS.TERMS_AND_PRIVACY,
  },
  signin: {
    title: 'Log in to continue using Picker',
    buttonAction: 'Log in',
    showFooter: true,
    text: "Don't have an account?",
    linkText: 'Sign up for picker',
    signupAction: 'Create an account',
    contextPath: PATHS.PRODUCTS_PAGE,
    forgotPassAction: 'Forgot Password?',
    forgotPassPath: PATHS.AUTH('forgotpass'),
  },
  forgotpass: {
    title: 'Get a new password',
    buttonAction: 'Reset password',
    showFooter: true,
    signinText: 'Remember it after all?',
    signinAction: 'Sign in',
    signinPath: PATHS.AUTH('signin'),
  },
  changepass: {
    title: 'Choose a new password',
    buttonAction: 'Change password',
  },
};

const AuthBox = (props) => {
  const darkMode = useDarkMode();

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : 'signup';
  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <>
      <SectionHeader
        title={props.title ? props.title : options.title}
        textColor="mainPage"
        caption={props.caption ? props.caption : ''}
        size={5}
        textAlign="center"
      />
      <Auth
        type={type}
        buttonAction={options.buttonAction}
        afterAuthPath={props.afterAuthPath}
        handleClose={props.handleClose}
        providers={props.providers}
        key={type}
        isModal={props.isModal}
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <br></br>
        {(props.isTryForFree || !props.isModal) && type !== 'forgotpass' && (
          <Grid item>
            <Typography component="p" variant="subtitle" mb={3}>
              {options.text}{' '}
              <StyledLink
                component={Link}
                to={options.contextPath}
                onClick={props.handleClose}
              >
                {options.linkText}
              </StyledLink>
            </Typography>
          </Grid>
        )}
        <br></br>
        <Grid item>
          {!props.isModal && (
            <PickerTagLogo
              width="300px"
              fill={darkMode.value ? '#f2f2f2' : '#000'}
            />
          )}
          {type === 'signup' && (
            <Typography color="grey" component="p" variant="subtitle2">
              By creating an account, you accept our{' '}
              <StyledLink
                component={Link}
                to={PATHS.TERMS_AND_PRIVACY}
                onClick={props.handleClose}
                color="mainPage"
                target="_blank"
                underline="none"
              >
                terms
              </StyledLink>
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AuthBox;
